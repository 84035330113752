import React from 'react'
import '@fontsource/yantramanav/500.css'
import Footer from './footer'

export default function Layout({ children }) {
  return (
    <div
      className="bg-gray-theme box-border px-3 md:px-10 font-default pt-4 md:pt-14 font-medium text-9xl
       md:text-[15rem] lg:text-10xl break-all leading-[0.74em] md:leading-pc tracking-[-0.1rem] md:tracking-[-0.4rem]"
    >
      <main>
        {children}
      </main>
      <Footer />
    </div>
  )
}