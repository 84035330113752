import React from 'react'
import Layout from '../components/layout'
import { useState } from 'react'
import SEO from '../components/seo'

export default function Index() {
  const [mapCount, setMapCount] = useState(0)
  const [phoneCount, setPhoneCount] = useState(0)
  const [emailCount, setEmailCount] = useState(0)

  const handleClickMap = (e) => {
    let map = document.getElementById('map')
    let phone = document.getElementById('phone')
    let email = document.getElementById('email')
    if (mapCount === 0) {
      setMapCount(1)
      if (window.matchMedia && window.matchMedia('(max-device-width: 768px)').matches) {
        e.preventDefault()
        map.classList.add('text-white')
        phone.classList.remove('text-white')
        email.classList.remove('text-white')
      }
      setPhoneCount(0)
      setEmailCount(0)
    } else if (mapCount === 1) {
      setMapCount(0)
      map.classList.remove('text-white')
    } else {
      setMapCount(0)
    }
  }

  const handleClickPhone = (e) => {
    let map = document.getElementById('map')
    let phone = document.getElementById('phone')
    let email = document.getElementById('email')
    if (phoneCount === 0) {
      setPhoneCount(1)
      if (window.matchMedia && window.matchMedia('(max-device-width: 768px)').matches) {
        e.preventDefault()
        phone.classList.add('text-white')
        map.classList.remove('text-white')
        email.classList.remove('text-white')
      }
      setMapCount(0)
      setEmailCount(0)
    } else if (mapCount === 1) {
      setPhoneCount(0)
      phone.classList.remove('text-white')
    } else {
      setPhoneCount(0)
    }
  }

  const handleClickEmail = (e) => {
    let map = document.getElementById('map')
    let phone = document.getElementById('phone')
    let email = document.getElementById('email')
    if (emailCount === 0) {
      setEmailCount(1)
      if (window.matchMedia && window.matchMedia('(max-device-width: 768px)').matches) {
        e.preventDefault()
        email.classList.add('text-white')
        map.classList.remove('text-white')
        phone.classList.remove('text-white')
      }
      setPhoneCount(0)
      setMapCount(0)
    } else if (mapCount === 1) {
      setEmailCount(0)
      email.classList.remove('text-white')
    } else {
      setEmailCount(0)
    }
  }
  return (
    <Layout>
      <SEO title="EPOH inc. | エポー" pathname="/" image={{ src: '/ogp.jpg', width: 1200, height: 630 }} />
      <h1 className="block transition duration-500 ease-in-out">CREATIVE STUDIO EPOH</h1>
      <a
        id="map"
        href="https://goo.gl/maps/P9hfFZxBym69nxUV6"
        target="_blank"
        rel="noopener noreferrer"
        className="block lg:hover:text-white transition duration-500 ease-in-out"
        onClick={handleClickMap}
      >
        35°39'08.5"N 139°42'11.9"E
      </a>
      <a
        id="phone"
        href="tel:03-6712-7042"
        className="break-all lg:hover:text-white transition duration-500 ease-in-out "
        onClick={handleClickPhone}
      >
        <span className="float-left">+81.</span>
        <span className="text-70% block float-left mt-[-0.4rem] md:mt-[-0.8rem] lg:mt-[-1.3rem] w-9 md:w-auto">(</span>
        <span className="float-left block">0</span>
        <span className="float-left block text-70% mt-[-0.4rem] md:mt-[-0.8rem] lg:mt-[-1.3rem] w-2 md:w-auto">)</span>
        <span className="float-left block">3.6712.7042</span>
      </a>
      <a
        id="email"
        href="mailto:info@epoh-inc.com"
        className="break-all clear-both block lg:hover:text-white transition duration-500 ease-in-out"
        onClick={handleClickEmail}
      >
        <span className="float-left block">INFO</span>
        <span className="float-left block text-80% mt-[-0.9rem] md:mt-[-1.8rem] lg:mt-[-3rem] w-10">@</span>
        <span className="float-left block">EPOH</span>
        <span className="float-left block mt-[-0.1rem] md:mt-[-0.5rem] w-4">-</span>
        <span className="float-left block">INC.COM</span>
      </a>
      <div className="clear-both" />
    </Layout>
  )
}