import React from 'react'

export default function Footer() {
  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` })
  }
  return (
    <footer className="mt-[100px] md:mt-[300px] pb-[100px] md:pb-[250px]">
      <h1 className="w-full mx-auto cursor-pointer" onClick={handleClick}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1266 220" className="hidden md:block">
          <title>EPOH Inc.</title>
          <g id="f4f02b32-8d5c-466f-8ba4-e344ce0a75f6" data-name="Layer 2">
            <g id="b7b2fea2-aacb-4504-80cb-3ffa1d3c698a" data-name="text">
              <rect x="569.97" y={10} width="125.75" height="125.75" style={{ fill: 'none' }} />
              <rect width={1266} height={220} style={{ fill: 'none' }} />
              <path
                d="M1266,179.74a7.12,7.12,0,0,1-.62,2.92,7.27,7.27,0,0,1-1.66,2.39,7.73,7.73,0,0,1-2.52,1.59,8.35,8.35,0,0,1-3.11.59,8.08,8.08,0,0,1-4-1,7.34,7.34,0,0,1-2.84-2.71,7.37,7.37,0,0,1,0-7.55,7.34,7.34,0,0,1,2.84-2.71,8.52,8.52,0,0,1,8,0A7.39,7.39,0,0,1,1265,176,7.18,7.18,0,0,1,1266,179.74Zm-13.62-3.13a6.19,6.19,0,0,0,0,6.26,6.11,6.11,0,0,0,2.37,2.25,6.86,6.86,0,0,0,3.34.82,6.56,6.56,0,0,0,4.69-1.8,6,6,0,0,0,0-8.8,6.56,6.56,0,0,0-4.69-1.8,6.86,6.86,0,0,0-3.34.83A6.16,6.16,0,0,0,1252.38,176.61Zm7.12,1.38v-1.55l0,0a3.05,3.05,0,0,1,2.42.87,3.76,3.76,0,0,1,.83,2.6,3.41,3.41,0,0,1-1.11,2.68,4.25,4.25,0,0,1-3,1h-1.25a4.27,4.27,0,0,1-3-1,3.4,3.4,0,0,1-1.11-2.67,3.76,3.76,0,0,1,.84-2.61,3,3,0,0,1,2.41-.88l.06,0V178a1.93,1.93,0,0,0-1.44.47,2,2,0,0,0-.45,1.44,1.79,1.79,0,0,0,.71,1.51,3.15,3.15,0,0,0,1.94.54h1.26a3.18,3.18,0,0,0,2-.54,1.79,1.79,0,0,0,.71-1.51,2,2,0,0,0-.44-1.44A2,2,0,0,0,1259.5,178Z" />
              <path d="M1256.79,153.45h2v6.71h4.86v-7.79h2v10.36H1250.4V152.44h2v7.72h4.34Z" />
              <path
                d="M1259.93,144.28v3.44h5.82v2.56H1250.4v-6a5.75,5.75,0,0,1,1.3-4,4.9,4.9,0,0,1,6.94,0A5.76,5.76,0,0,1,1259.93,144.28Zm-7.48,3.44h5.43v-3.44a2.85,2.85,0,0,0-.74-2.12,2.66,2.66,0,0,0-2-.74,2.71,2.71,0,0,0-2,.74,2.84,2.84,0,0,0-.75,2.12Z" />
              <path
                d="M1256.72,124.33h2.76a6.49,6.49,0,0,1,4.69,1.77,6.06,6.06,0,0,1,1.83,4.56,5.92,5.92,0,0,1-1.83,4.51,6.59,6.59,0,0,1-4.69,1.73h-2.76a6.59,6.59,0,0,1-4.68-1.73,5.87,5.87,0,0,1-1.84-4.47,6.4,6.4,0,0,1,.85-3.31,5.73,5.73,0,0,1,2.32-2.26A7,7,0,0,1,1256.72,124.33Zm2.76,2.56h-2.78a4.64,4.64,0,0,0-3.17,1,3.42,3.42,0,0,0-1.22,2.74,3.29,3.29,0,0,0,1.22,2.69,4.88,4.88,0,0,0,3.17,1h2.78a5,5,0,0,0,3.2-1,3.3,3.3,0,0,0,1.22-2.7,3.4,3.4,0,0,0-1.22-2.75A4.82,4.82,0,0,0,1259.48,126.89Z" />
              <path d="M1250.4,109.73h15.35v2.55h-6.55v6.87h6.55v2.57H1250.4v-2.57h6.75v-6.87h-6.75Z" />
              <path d="M1249.3,97.15h2.25V99.7h-2.25Zm5,0h11.41V99.7h-11.41Z" />
              <path
                d="M1254.13,88.59a3.57,3.57,0,0,1,1.1-2.81,4.93,4.93,0,0,1,3.35-1h7.17v2.57h-7.14a2.58,2.58,0,0,0-1.83.52,2.15,2.15,0,0,0-.55,1.64,2.61,2.61,0,0,0,1.24,2.3h8.28V94.4h-11.41V92.06l1.65-.16A3.7,3.7,0,0,1,1254.13,88.59Z" />
              <path
                d="M1260.26,80.08h0q3.69,0,3.69-2.65a2.31,2.31,0,0,0-.55-1.58,1.76,1.76,0,0,0-1.38-.63V72.91l0,0a3.4,3.4,0,0,1,2.77,1.3,5.33,5.33,0,0,1-.42,7.11,6.15,6.15,0,0,1-4.16,1.37h-.37a6.14,6.14,0,0,1-4.15-1.37,4.82,4.82,0,0,1-1.59-3.85,4.69,4.69,0,0,1,1.2-3.34,3.81,3.81,0,0,1,3-1.24l.06,0v2.34a2.22,2.22,0,0,0-1.6.61,2.15,2.15,0,0,0-.64,1.6,2.22,2.22,0,0,0,1,2,5,5,0,0,0,2.69.63Z" />
              <path d="M1263.29,68h2.46v2.55h-2.46Z" />
              <circle cx="632.86" cy="72.86" r="62.86" />
              <path
                d="M570,187.14v1.08c10.74,0,21.41,6.78,26.71,10.35a62.88,62.88,0,0,0,72.3,0c5.3-3.57,16-10.35,26.71-10.35v-1.08Z" />
              <polygon
                points="804.29 72.86 804.29 118.57 781.44 118.57 781.44 72.86 758.58 72.86 758.58 187.14 781.44 187.14 781.44 141.43 804.29 141.43 804.29 187.14 827.15 187.14 827.15 72.86 804.29 72.86" />
              <path d="M438.58,72.86V187.14h22.86V141.43h45.71V72.86Zm45.71,45.71H461.44V95.71h22.85Z" />
              <polygon
                points="370.01 95.71 370.01 72.86 301.44 72.86 301.44 187.14 370.01 187.14 370.01 164.29 324.29 164.29 324.29 141.43 370.01 141.43 370.01 118.57 324.29 118.57 324.29 95.71 370.01 95.71" />
            </g>
          </g>
        </svg>

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1025 220" className="md:hidden">
          <title>EPOH Inc.</title>
          <g id="b6601785-3365-491a-b75f-e1cfa2603e21" data-name="Layer 2">
            <g id="b38ca9b4-5c0e-4f4e-8088-9419c2cc70fd" data-name="text">
              <rect x="449.64" y={10} width="125.71" height="125.71" style={{ fill: 'none' }} />
              <rect width={1025} height={220} style={{ fill: 'none' }} />
              <path
                d="M1025,179.74a7.12,7.12,0,0,1-.62,2.92,7.27,7.27,0,0,1-1.66,2.39,7.73,7.73,0,0,1-2.52,1.59,8.35,8.35,0,0,1-3.11.59,8.08,8.08,0,0,1-4-1,7.34,7.34,0,0,1-2.84-2.71,7.37,7.37,0,0,1,0-7.55,7.34,7.34,0,0,1,2.84-2.71,8.52,8.52,0,0,1,8,0A7.39,7.39,0,0,1,1024,176,7.18,7.18,0,0,1,1025,179.74Zm-13.62-3.13a6.21,6.21,0,0,0,0,6.27,6.16,6.16,0,0,0,2.37,2.24,6.86,6.86,0,0,0,3.34.82,6.56,6.56,0,0,0,4.69-1.8,6,6,0,0,0,0-8.8,6.56,6.56,0,0,0-4.69-1.8,6.86,6.86,0,0,0-3.34.83A6.16,6.16,0,0,0,1011.38,176.61Zm7.12,1.38v-1.55l0,0a3,3,0,0,1,2.42.88,3.71,3.71,0,0,1,.83,2.59,3.41,3.41,0,0,1-1.11,2.68,4.25,4.25,0,0,1-3,1h-1.25a4.27,4.27,0,0,1-3-1,3.4,3.4,0,0,1-1.11-2.67,3.71,3.71,0,0,1,.84-2.6,3,3,0,0,1,2.41-.89l.06,0V178a1.93,1.93,0,0,0-1.44.47,2,2,0,0,0-.45,1.44,1.78,1.78,0,0,0,.71,1.51,3.15,3.15,0,0,0,1.94.54h1.26a3.18,3.18,0,0,0,1.95-.54,1.78,1.78,0,0,0,.71-1.51,2,2,0,0,0-.44-1.44A2,2,0,0,0,1018.5,178Z" />
              <path d="M1015.79,153.44h2.05v6.72h4.86v-7.79h2v10.36H1009.4V152.44h2.05v7.72h4.34Z" />
              <path
                d="M1018.93,144.28v3.44h5.82v2.56H1009.4v-6a5.75,5.75,0,0,1,1.3-4,4.9,4.9,0,0,1,6.94,0A5.76,5.76,0,0,1,1018.93,144.28Zm-7.48,3.44h5.43v-3.44a2.85,2.85,0,0,0-.74-2.12,2.66,2.66,0,0,0-2-.74,2.71,2.71,0,0,0-2,.74,2.84,2.84,0,0,0-.75,2.12Z" />
              <path
                d="M1015.72,124.33h2.76a6.49,6.49,0,0,1,4.69,1.77,6.06,6.06,0,0,1,1.83,4.56,5.92,5.92,0,0,1-1.83,4.51,6.59,6.59,0,0,1-4.69,1.73h-2.76a6.59,6.59,0,0,1-4.68-1.73,5.87,5.87,0,0,1-1.84-4.47,6.4,6.4,0,0,1,.85-3.31,5.73,5.73,0,0,1,2.32-2.26A7,7,0,0,1,1015.72,124.33Zm2.76,2.56h-2.78a4.64,4.64,0,0,0-3.17,1,3.42,3.42,0,0,0-1.22,2.74,3.29,3.29,0,0,0,1.22,2.69,4.88,4.88,0,0,0,3.17,1h2.78a5,5,0,0,0,3.2-1,3.3,3.3,0,0,0,1.22-2.7,3.4,3.4,0,0,0-1.22-2.75A4.82,4.82,0,0,0,1018.48,126.89Z" />
              <path d="M1009.4,109.73h15.35v2.54h-6.55v6.88h6.55v2.57H1009.4v-2.57h6.75v-6.88h-6.75Z" />
              <path d="M1008.3,97.15h2.25V99.7h-2.25Zm5,0h11.41V99.7h-11.41Z" />
              <path
                d="M1013.13,88.59a3.57,3.57,0,0,1,1.1-2.81,4.93,4.93,0,0,1,3.35-1h7.17v2.57h-7.14a2.58,2.58,0,0,0-1.83.52,2.14,2.14,0,0,0-.55,1.63,2.62,2.62,0,0,0,1.24,2.31h8.28V94.4h-11.41V92.06l1.65-.16A3.71,3.71,0,0,1,1013.13,88.59Z" />
              <path
                d="M1019.26,80.08h0q3.69,0,3.69-2.65a2.31,2.31,0,0,0-.55-1.58,1.76,1.76,0,0,0-1.38-.63V72.91l.05,0a3.36,3.36,0,0,1,2.77,1.29,5.33,5.33,0,0,1-.42,7.11,6.15,6.15,0,0,1-4.16,1.37h-.37a6.14,6.14,0,0,1-4.15-1.37,4.82,4.82,0,0,1-1.59-3.85,4.69,4.69,0,0,1,1.2-3.34,3.81,3.81,0,0,1,3-1.24l.06,0v2.33a2.22,2.22,0,0,0-1.6.61,2.15,2.15,0,0,0-.64,1.6,2.22,2.22,0,0,0,1,2,5,5,0,0,0,2.69.63Z" />
              <path d="M1022.29,68h2.46v2.55h-2.46Z" />
              <circle cx="512.5" cy="72.86" r="62.86" />
              <path
                d="M449.64,187.14v1.08c10.73,0,21.41,6.78,26.71,10.35a62.88,62.88,0,0,0,72.3,0c5.3-3.57,16-10.35,26.71-10.35v-1.08Z" />
              <polygon
                points="683.93 72.86 683.93 118.57 661.08 118.57 661.08 72.86 638.22 72.86 638.22 187.14 661.08 187.14 661.08 141.43 683.93 141.43 683.93 187.14 706.79 187.14 706.79 72.86 683.93 72.86" />
              <path d="M318.22,72.86V187.14h22.85V141.43h45.72V72.86Zm45.71,45.71H341.07V95.71h22.86Z" />
              <polygon
                points="249.65 95.71 249.65 72.86 181.07 72.86 181.07 187.14 249.65 187.14 249.65 164.29 203.93 164.29 203.93 141.43 249.65 141.43 249.65 118.57 203.93 118.57 203.93 95.71 249.65 95.71" />
            </g>
          </g>
        </svg>
      </h1>
    </footer>
  )
}